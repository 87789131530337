import React, {useEffect} from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import {createTheme, ThemeProvider} from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#56122c',
        },
    },
});
export default function Alert({title, message, onClose}) {
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if(title !== '') setOpen(true);
    }, [title]);

    const handleClose = () => {
        setOpen(false);
        onClose();
    };

    return (
        <Dialog maxWidth={'lg'} open={open} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent style={{ whiteSpace: "pre-line" }} dividers>
                {message}
            </DialogContent>
            <DialogActions>
                <ThemeProvider theme={theme}>
                    <Button variant="contained" color="primary"
                            onClick={handleClose}>Cerrar</Button>
                </ThemeProvider>

            </DialogActions>
        </Dialog>
    );
}