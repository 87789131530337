import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import HistoryIcon from '@mui/icons-material/History';
import {Link} from "react-router-dom";

export const MainListItems = () => {
    const pathname = window.location.pathname;
    return <React.Fragment>
        <ListItemButton component={Link} to="/"  selected={pathname === '/'}>
            <ListItemIcon>
                <PointOfSaleIcon/>
            </ListItemIcon>
            <ListItemText primary="Caja"/>
        </ListItemButton>
        <ListItemButton component={Link} to="/historial" selected={pathname === '/historial'}>
            <ListItemIcon>
                <HistoryIcon/>
            </ListItemIcon>
            <ListItemText primary="Historial pagos"/>
        </ListItemButton>
    </React.Fragment>
};