import React, { useState, useEffect, useRef } from "react";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Head from "../Head/index";
import Content from "../Content";
import Title from "../Title";
import Dashboard from "../Dashboard/dashboard";
import Details from "../Details";
import Total from "../Total";
import { useNavigate } from "react-router-dom";
import { Cashbox, Invoices, Budget, initApi } from '../../services/api';
import ModalCashBoxes from './selectCashbox';
import OpenCloseCashbox from './openCloseCashbox';
import TotalPaid from './totalPaid';
import Stack from '@mui/material/Stack';
import Alert from '../../components/Alert';
import DocumentDetails from '../../components/DocumentDetails';
import EpsonPrinter from '../Printer';

import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import VpnKeyOffIcon from '@mui/icons-material/VpnKeyOff';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function Facturacion() {
    const [cashBoxes, setCashBoxes] = useState([]);
    const [selectedCashBox, setselectedCashBox] = useState(null);
    const [cashBoxInfo, setcashBoxInfo] = useState({});
    const [openCashbox, setOpenCashbox] = useState(false);
    const [invoices, setInvoices] = useState({});
    const [selectedInvoices, setSelectedInvoices] = useState({});
    const [total, setTotal] = useState(0);
    const [transactionId, setTransactionId] = useState(0);
    const [amount, setAmount] = useState(0);
    const [paymentType, setPaymentType] = useState('factura');
    const [user, setUser] = useState({});
    const [endSale, setEndSale] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [amountIsDisabled, setAmounIsDisabled] = useState(true);

    const inputInvoice = useRef(null);
    const inputIAmount = useRef(null);

    let navigate = useNavigate();

    const isOpen = () => cashBoxInfo && cashBoxInfo.caja != null;
    const isInvoice = () => paymentType === 'factura';
    const isBudget = () => selectedInvoices && selectedInvoices.type === 3;

    const selectCashbox = (id) => {
        localStorage.setItem('caja', id);
        setselectedCashBox(id);
        Cashbox.isOpen(id).then(data => {
            setcashBoxInfo(data);
        });
    }

    const openCachbox = (amount) => {
        if (!isOpen()) {
            Cashbox.open(selectedCashBox, amount).then(result => {
                const caja = localStorage.getItem('caja');
                setOpenCashbox(false);
                setcashBoxInfo(caja);
            });
        } else {
            Cashbox.close(cashBoxInfo.id, amount).then(result => {
                // alert("Saldo final: ", `Importe apertura: ${result.importeApertura}\nImporte cierre: ${result.importeCierre}\nTotal: ${result.total}\n`);
                alert("Cierre de caja resumen", `Facturas
                Movimientos ${result.resumen.factura.movimientos} Importe: ${result.resumen.factura.importe}\n
                Abonos
                Movimientos ${result.resumen.abonoFactura.movimientos} Importe: ${result.resumen.abonoFactura.importe}\n
                Presupuesto:
                Movimientos ${result.resumen.presupuesto.movimientos} Importe: ${result.resumen.presupuesto.importe}\n
                Total:
                Movimientos ${result.resumen.total.movimientos} Importe: ${result.resumen.total.importe}\n
                
                Conceptos
                ${result.conceptos.map(concepto => `${concepto.conceptoCobro__descripcion}\nMovimientos: ${concepto.totalMovimientos} Importe: ${concepto.totalImporte}\n\n`)}
                `);
                setOpenCashbox(false);
            });
        }

    }

    const selectInvoice = (e) => {
        if (e.key === 'Enter') {
            if (!isOpen()) {
                alert("Error", "La caja no esta abierta.");
            } else if (isInvoice()) {
                Invoices.get(e.target.value).then(result => {
                    console.log(result);
                    result.vencida2 = result.vencida;
                    result.vencida = false;

                    if(result.permitirAbonoLibre || result.permitirAbono){
                        setAmounIsDisabled(false);
                    }

                    if(result.vencida){
                        alert("Factura vencida.", <DocumentDetails document={result} />);
                    } else if (!result.pagada) {
                        result.paymentType = paymentType;
                        result.amount = result.saldo;
                        result.monto_pagar = result.monto_pagar;
                        result.type = 1;
                        if (amount !== result.importe && (result.permitirAbonoLibre || result.permitirAbono)) {
                            result.type = 2;
                            result.paymentType = "Abono factura";
                        }
                        setSelectedInvoices(result);
                        setAmount(result.monto_pagar);
                        e.target.value = '';
                        inputIAmount.current.focus();
                    }else {
                        alert("Factura pagada.", <DocumentDetails document={result} />);
                        inputInvoice.current.focus();
                    }
                }).catch(() => {
                    alert("Error", "La factura no existe.");
                    inputInvoice.current.focus();
                });
            } else {
                Budget.get(e.target.value).then(result => {
                    if (result.estado === 1 || result.estado === 2) {
                        if(result.negociado){
                            result.amount = result.anticipo;
                        }else{
                            result.amount = result.importe;
                        }
                        result.paymentType = paymentType;
                        
                        result.type = 3;
                        setSelectedInvoices(result);
                        setAmount(result.monto_pagar);
                        e.target.value = '';
                        inputIAmount.current.focus();
                    } else {
                        alert("Presupuesto ya pagado.", <DocumentDetails document={result} />);
                        inputInvoice.current.focus();
                    }
                }).catch(() => {
                    alert("Error", "El presupuesto no existe.");
                });


            }
        }
    }

    const deleteInvoice = (id) => {
        const newInvoices = { ...invoices };
        setTotal(prev => prev - parseFloat(newInvoices[id].amount));
        delete newInvoices[id];
        setInvoices(newInvoices);
    }

    const addInvoice = () => {
        if (amount > parseFloat(selectedInvoices.amount)) {
            alert("Error", "La cantidad pagada no puede ser mayor al saldo que se debe.");
        }else if(!selectedInvoices.permitirAbono && selectedInvoices.amount != amount){
            alert("Error", "No permite abono.");
        } else if(!selectedInvoices.permitirAbonoLibre && selectedInvoices.permitirAbono && selectedInvoices.monto_pagar > amount){
            alert("Error", "Abono minimo de " + selectedInvoices.monto_pagar);
        }else {
            if (selectedInvoices.id !== undefined && !(selectedInvoices.id in invoices)) {
                setTotal(prev => prev + parseFloat(amount));
                selectedInvoices.amount = parseFloat(amount);
                setInvoices({ ...invoices, [selectedInvoices.id]: selectedInvoices });
            }
            setSelectedInvoices({});
            setAmount(0);
            inputIAmount.current.value = '0';
            inputInvoice.current.focus();
        }

    }

    const resetSale = () => {
        setInvoices({});
        setTotal(0);
    }

    const addSale = (totalPaied) => {
        setEndSale(false);
        const sales = Object.keys(invoices).map((idx) => {
            const sale = invoices[idx];
            
            return { tipo: sale.type, documento: sale.id, importe: sale.amount, adeudo:  (parseFloat(sale.saldo) - sale.amount).toFixed(2) }
        });
        Cashbox.addSale(totalPaied, total, cashBoxInfo.id, sales).then(result => {
            resetSale();
            setTransactionId(result.id_transaccion);
            
            const msg = sales.map(sale => {
                let adeudo = sale.adeudo;
                if(!adeudo){
                    adeudo = sale.importe - total;
                }
                return `Documento ${sale.documento} abonado $${sale.importe} adeudo $${adeudo}\n`;
            });
            alert("Venta agregada correctamente.", "Cambio: " + (totalPaied - total).toFixed(2) + "\n\n" + msg);
            setEndSale(false);
        }).catch(e => {
            setEndSale(false);
            alert("Error al agregar venta");
        });

        inputInvoice.current.focus();
    }
 
    const alert = (title, message) => {
        setAlertTitle(title);
        setAlertMessage(message);
    }

    useEffect(() => {
        const user = localStorage.getItem('user');
        if (!user) {
            navigate("/login", { replace: true });
        } else {
            setUser(JSON.parse(user));
            initApi();
            if (selectedCashBox !== null) {
                Cashbox.isOpen(selectedCashBox).then(caja => {
                    setcashBoxInfo(caja);
                });
            }
            inputInvoice.current.focus();
        }
    }, [openCashbox]);


    /*useEffect(() => {
        const handleKeyPress = event => {
            if (event.key === 'F11') {
                event.preventDefault();
                event.stopImmediatePropagation();
                if (total !== 0)
                    setEndSale(true);
                else
                    alert("Error", "Selecciona por lo menos un documento.");
            }
        };
        
        window.addEventListener("keydown", handleKeyPress);
        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        }
    }, [total]);*/

    useEffect(() => {
        const user = localStorage.getItem('user');
        if (!user) {
            navigate("/login", { replace: true });
        } else {
            initApi();
            const caja = localStorage.getItem('caja');
            Cashbox.getAll().then(cajas => {
                const cajasDict = Object.assign({}, ...cajas.map((x) => ({ [x.id]: x })));
                setCashBoxes(prevState => {
                    return cajasDict;
                });
            });
            if (caja !== null) {
                setselectedCashBox(caja);
                Cashbox.isOpen(caja).then(caja => {
                    setcashBoxInfo(caja);
                });
            }
        }
    }, [selectedCashBox]);

    const cashbox = cashBoxes[selectedCashBox];
    return (
        <React.Fragment>
            <Alert title={alertTitle} message={alertMessage} onClose={() => {
                setTimeout(() => {
                    setAlertTitle('');
                    setAlertMessage('');
                }, 500);
            }} />
            {/*<ModalCashBoxes open={selectedCashBox === null} cashBoxes={cashBoxes} onSelect={selectCashbox} />*/}
            <OpenCloseCashbox open={openCashbox} openCashBox={!isOpen()} onSelect={openCachbox} onCancel={() => setOpenCashbox(false)} />
            <TotalPaid open={endSale} addSale={addSale} importe={total} onCancel={() => setOpenCashbox(setEndSale(false))} />
            <Dashboard>
                <Head
                    left={
                        <div>
                            <Stack direction="row" spacing={1}>
                                <PointOfSaleIcon sx={{ fontSize: 22 }} color="#56122c" /> <b>{cashbox?.nombre} {" "}</b>

                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <AccountCircleIcon sx={{ fontSize: 22 }} color="#56122c" /> <b>{user?.user?.username}</b>
                            </Stack>
                        </div>
                    }
                    center={
                        <div>
                            <Title>
                                Transacción de Caja
                            </Title>
                        </div>
                    }
                    right={
                        <div>
                            <EpsonPrinter caja={cashbox?.nombre} cajero={user?.user?.name} transactionId={transactionId} setTransacctionId={() => {setTransactionId(0)}} />
                            <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
                                {/*<Button variant="contained" color="success" startIcon={<CurrencyExchangeIcon />}
                                    onClick={() => {
                                        localStorage.removeItem('caja');
                                        setselectedCashBox(null);
                                    }}>Cambiar de caja</Button>*/}
                                {!isOpen() ?
                                    <Button variant="contained" startIcon={<PointOfSaleIcon />}
                                        onClick={() => setOpenCashbox(true)}>Abrir
                                        Caja</Button> :
                                    <Button variant="contained" startIcon={<VpnKeyOffIcon />}
                                        onClick={() => setOpenCashbox(true)}>Cerrar
                                        Caja</Button>
                                }
                            </Stack>
                        </div>
                    } />
                <Content>
                    <Grid container spacing={{ xs: 2, md: 3, mt: 5 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={6} sm={12} md={6}>
                            <ToggleButtonGroup
                                color="primary"
                                value={paymentType}
                                onChange={(event) => {
                                    setSelectedInvoices({});
                                    setPaymentType(event.target.value);
                                    inputInvoice.current.focus();
                                }}
                                aria-label="Tipo de Pago"
                            >
                                <ToggleButton value="factura">Pago Factura</ToggleButton>
                                <ToggleButton value="presupuesto">Pago de presupuesto</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid className={"text-right"} item xs={6} sm={12} md={6}>
                            <TextField fullWidth id="outlined-basic" type="number"
                                label={isInvoice() ? "Factura" : "Presupuesto"}
                                variant="outlined" onKeyDown={selectInvoice} inputRef={inputInvoice} />
                        </Grid>
                    </Grid>
                    <Title>Datos generales de Facturación</Title>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={6} sm={12} md={6}>
                            <p className="mb-1">{isInvoice() ? "Factura" : "Presupuesto"}: <b>{selectedInvoices?.id}</b>
                            </p>
                            <p className="mb-1">Individuo: <b>{selectedInvoices?.nombre_nompleto}</b></p>
                            <p className="mb-1">Domicilio: <b>{selectedInvoices?.domicilio}</b></p>
                        </Grid>
                        <Grid className={"text-right"} item xs={6} sm={12} md={6}>
                            <p className="mb-1">Contrato: <b>{selectedInvoices?.contrato}</b></p>
                            <p className="mb-1">Periodo: <b>{selectedInvoices?.periodo}</b></p>
                            <p className="mb-1">Importe: <b>${selectedInvoices?.importe}</b></p>
                            <p className="mb-1">Permite abonar: <b>{selectedInvoices.permitirAbono ? "Sí": "No"}</b></p>
                            <p className="mb-1">Vencida: <b>{selectedInvoices.vencida2 ? "Sí": "No"}</b></p>
                        </Grid>
                    </Grid>
                    <div className={'text-right'}>
                        <Stack direction="row" spacing={1} justifyContent={"flex-end"}>
                            <p className="fw-bold">A pagar</p>
                            <TextField
                                type="number"
                                value={amount}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        addInvoice();
                                    }
                                }}
                                inputRef={inputIAmount}
                                onChange={(e) => {
                                    setAmount(e.target.value);
                                }}
                                disabled={amountIsDisabled}
                            />
                            <Button
                                variant="contained"
                                startIcon={<AddCircleOutlineIcon />}
                                onClick={() => addInvoice()}
                            >Agregar</Button>
                        </Stack>
                    </div>
                </Content>

                <Grid item xs={12} md={8} lg={9}>
                    <Details invoices={invoices} deleteInvoice={deleteInvoice} />
                </Grid>
                {/* Total */}
                <Grid item xs={12} md={4} lg={3}>
                    <Total total={total} addSale={() => {
                        if (total !== 0)
                            setEndSale(true);
                        else
                            alert("Error", "Selecciona por lo menos un documento.");
                    }} resetSale={resetSale} />
                </Grid>
            </Dashboard>
        </React.Fragment>
    )

}
