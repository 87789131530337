import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import Title from '../Title/index';

export default function Details({invoices, deleteInvoice}) {
    return (
        <React.Fragment>
            <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                 <Title>Detalles</Title>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Tipo</TableCell>
                                <TableCell align="right">Documento</TableCell>
                                <TableCell align="right">Individuo</TableCell>
                                <TableCell align="right">Importe</TableCell>
                                <TableCell align="right">Acciones</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        {Object.keys(invoices).map( (id) => <TableBody>
                            <TableCell component="th" style={{textTransform: "capitalize"}} scope="row">{invoices[id].paymentType}</TableCell>
                            <TableCell align="right">{invoices[id].id}</TableCell>
                            <TableCell align="right">{invoices[id].nombre_nompleto}</TableCell>
                            <TableCell align="right">${invoices[id].amount}</TableCell>
                            <TableCell align="right">
                                <IconButton aria-label="cancel" size="small">
                                    <CancelIcon onClick={() => deleteInvoice(id)} fontSize="small"/>
                                </IconButton>
                            </TableCell>
                        </TableBody>)}
                    </Table>
                </TableContainer>
            </Paper>
        </React.Fragment>
    )

}
