import React from 'react';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import Stack from "@mui/material/Stack";
import HomeIcon from "@mui/icons-material/Home";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import TextField from "@mui/material/TextField";

const theme = createTheme({
    palette: {
        primary: {
            main: '#56122c',
        },
    },
});

export default function DocumentDetails({document}) {
    let fechaPago = "";
    if(document.pagada){
        let date_hour = document.fechaCambioEstado.split("T");
        console.log(date_hour);
        let date = date_hour[0].split("-");
        fechaPago += date[2] + "/" + date[1] + "/" + date[0];
    }

    return <div>
        <ThemeProvider theme={theme}>
            <div className={'text-right mb-4'}>
                <span className="h5">Contrato: <b> {document.contrato}</b></span> <br/>
                <span className=" h5" >Periodo: <b> {document.periodo}</b></span> <br/>
                {document.pagada && <div><span className=" h5" >Fecha de pago: <b> {fechaPago}</b></span> <br/></div>}
                {document.pagada && <div><span className=" h5" >Caja: <b> {document?.cajaPago}</b></span> <br/></div>}
            </div>

            <span className="h4" style={{marginTop:10}}>Individuo: <b>{document.nombre_nompleto}</b></span><br/>
            <span className="h4" style={{marginTop:10}}>Domicilio: <b>{document.domicilio}</b></span>

            <hr/>
            <div className={'text-right'}>
                <Stack direction="row" spacing={1} justifyContent={"flex-end"}>
                    <span className="fw-bold h4">Importe: {document.importe}</span>
                </Stack>
            </div>
        </ThemeProvider>
    </div>
}