import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

import { Cashbox, initApi } from '../../../services/api';
import Dashboard from "../../Dashboard/dashboard";
import Head from "../../Head";
import Content from "../../Content";
import Title from "../../Title";
import HistoryIcon from '@mui/icons-material/History';
import Stack from "@mui/material/Stack";

const columns = [
    { field: 'documento', headerName: 'ID', width: 90 },
    {
        field: 'estado',
        headerName: 'Estado',
        width: 150,
        valueGetter: (params) => params.row.estado == 1 ? "Realizado" : "Cancelado"
    },
    {
        field: 'importe',
        headerName: 'Importe',
        width: 150
    },
    {
        field: 'numeroMovimiento',
        headerName: 'Número de movimiento',
        type: 'number',
        width: 110,
    },
    {
        field: 'tipoDocumento',
        headerName: 'Tipo de documento',
        width: 160,
        valueGetter: (params) => params.row.tipoDocumento == 1 ? "Factura" : "Presupuesto"
    },
];

export default function Historial() {
    const [history, setHistory] = useState([]);
    const [total, setTotal] = useState(0);
    const caja = localStorage.getItem('caja');
    useEffect(() => {

        initApi();
        Cashbox.transaction(caja).then(result => {
            console.log(result);
            setHistory(result.results);
            const total = Object.keys(result.results)
                .reduce((partialSum, e) => {
                    if (result.results[e].estado === 1) {
                        return partialSum + parseFloat(result.results[e].importe);
                    } else {
                        return partialSum;
                    }
                }, 0);
            setTotal(parseFloat(total).toFixed(2));
        });
    }, []);
    return (
        <React.Fragment>

            <Dashboard>
                <Head
                    left={
                        <div>
                            <Stack direction="row" spacing={1}>
                                <HistoryIcon sx={{ fontSize: 30 }} color="primary" />
                                <Title>Historial de transacciones de pagos</Title>
                            </Stack>
                        </div>

                    }
                    right={
                        ""
                    } />
                <Content>
                    Total: {total}
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={history}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[10]}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                        />
                    </Box>
                </Content>
            </Dashboard>
        </React.Fragment>
    );
}