import React from "react";
import * as Sentry from "@sentry/browser";
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Login from "./components/Login/index";
import Historial from "./components/Caja/Historial";
import Facturacion from "./components/Facturacion";
import ReactDOM from 'react-dom/client';

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />}>
                </Route>
                <Route path="/" element={<Facturacion />}>
                </Route>
                <Route path="/historial" element={<Historial />}>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

Sentry.init({ dsn: "https://771ab7953e1a4e829270089a0e5df907@app.glitchtip.com/1867" });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);