import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

function Head(props) {
    return (
        <Grid item xs={12}>
            <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}}>
                    <Grid item xs={4} sm={12} md={4}>
                        {props.left}
                    </Grid>
                    <Grid item xs={4} sm={12} md={4}>
                        {props.center}
                    </Grid>
                    <Grid item xs={4} sm={12} md={4} className={"text-right"}>
                        {props.right}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>

    );
}

Head.propTypes = {
    left: PropTypes.node,
    center: PropTypes.node,
    right: PropTypes.node,
};

export default Head;