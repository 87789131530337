import React, { useEffect, useRef, useState } from "react";

import { Invoices, initApi } from '../../services/api';

const ThermalPrinter = ({ transactionId, setTransacctionId, cajero, caja }) => {
    const [printerIPAddress, setPrinterIPAddress] = useState(
        localStorage.getItem("printerIPAddress") || ""
    );
    const [printerPort, setPrinterPort] = useState(
        localStorage.getItem("printerPort") || ""
    );
    const [connectionStatus, setConnectionStatus] = useState("");
    const [ticketData, setTicketData] = useState(null);

    const ePosDevice = useRef();
    const printer = useRef();

    const STATUS_CONNECTED = "Conectada";
    const STATUS_DISCONNECTED = "Desconectada";

    useEffect(() => {
        if (printerIPAddress && printerPort) {
            connect();
        } else {
            requestPrinterInfo();
        }
        return () => {
            disconnect();
        };
    }, [printerIPAddress, printerPort]);

    useEffect(() => {
        if (connectionStatus === STATUS_DISCONNECTED) {
            setTimeout(() => {
                connect();
            }, 5000);
        }
    }, [connectionStatus]);

    useEffect(() => {
        console.log("Cambia transactionId: ", transactionId);
        if (transactionId > 0) {
            fetchTicketData(transactionId);
        }
    }, [transactionId]);

    const requestPrinterInfo = () => {
        const ip = prompt("Ingrese la dirección IP de la impresora");
        const port = prompt("Ingrese el puerto de la impresora");

        if (ip && port) {
            localStorage.setItem("printerIPAddress", ip);
            localStorage.setItem("printerPort", port);
            setPrinterIPAddress(ip);
            setPrinterPort(port);
        }
    };

    const connect = () => {
        if (!printerIPAddress || !printerPort) return;

        setConnectionStatus("Conectando... ");

        let ePosDev = new window.epson.ePOSDevice();
        ePosDevice.current = ePosDev;
        ePosDev.connect(printerIPAddress, printerPort, (data) => {
            if (data === "OK") {
                ePosDev.createDevice(
                    "local_printer",
                    ePosDev.DEVICE_TYPE_PRINTER,
                    { crypto: true, buffer: false },
                    (devobj, retcode) => {
                        if (retcode === "OK") {
                            printer.current = devobj;
                            setConnectionStatus(STATUS_CONNECTED);
                            if (ticketData) {
                                printTicket(ticketData);
                            }
                        } else {
                            console.error("Error al crear dispositivo de impresora:", retcode);
                            throw retcode;
                        }
                    }
                );
            } else {
                console.error("Error al conectar con la impresora:", data);
                setConnectionStatus(STATUS_DISCONNECTED);
            }
        });
    };

    const disconnect = () => {
        if (ePosDevice.current && printer.current) {
            ePosDevice.current.deleteDevice(printer.current);
            ePosDevice.current.disconnect();
        }
    };

    const fetchTicketData = async (transactionId) => {
        try {
            initApi();
            const data = await Invoices.ticket(transactionId);
            setTicketData(data);
            console.log("123: ", data);
            if (connectionStatus === STATUS_CONNECTED) {
                printTicket(data);
            }
        } catch (error) {
            console.error("Error fetching ticket data:", error);
        }
    };

    function obtenerFechaHoraFormatoLargo() {
        const fecha = new Date();

        // Obtener el nombre del día de la semana
        const diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        const diaSemana = diasSemana[fecha.getDay()];

        // Obtener el número del día del mes
        const diaMes = fecha.getDate();

        // Obtener el nombre del mes
        const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        const mes = meses[fecha.getMonth()];

        // Obtener el año
        const anio = fecha.getFullYear();

        // Obtener las horas, minutos y segundos y agregar ceros a la izquierda si es necesario
        const horas = agregarCeros(fecha.getHours(), 2);
        const minutos = agregarCeros(fecha.getMinutes(), 2);
        const segundos = agregarCeros(fecha.getSeconds(), 2);

        // Función auxiliar para agregar ceros a la izquierda
        function agregarCeros(numero, longitud) {
            let numeroString = numero.toString();
            while (numeroString.length < longitud) {
                numeroString = '0' + numeroString;
            }
            return numeroString;
        }

        // Formato largo: Lunes, 11 de Mayo de 2023 08:54:09
        const formatoLargo = `${diaSemana}, ${diaMes} de ${mes} de ${anio} ${horas}:${minutos}:${segundos}`;

        return formatoLargo;
    }

    const formatConcept = (concept, amount, width = 45) => {
        const amountFixed = parseFloat(amount).toFixed(2);
        const conceptWidth = width - amountFixed.length - 1; // 1 espacio entre el concepto y el importe
        const conceptPadded = concept.padEnd(conceptWidth, " ");
        return `${conceptPadded}$${amount}`;
    };

    const printTicket = (ticketData) => {
        if (!ticketData || !printer.current) return;

        const prn = printer.current;

        let datos = `${obtenerFechaHoraFormatoLargo()} \n\n` +
            'SISTEMA MUNICIPAL DE AGUA POTABLE Y \nALCANTARILLADO DE JEREZ\n' +
            'CALLE ANTES 39 \n' +
            'COL. CENTRO \n' +
            'RFC. SMA92022C7000 \n';
        prn.addText(datos);

        let c = `\nCajero: ${cajero}\n` +
            `Caja: ${caja}\n` +
            'Agencia: Jerez \n' +
            `Transacción: ${ticketData.transaccion} \n`;
        prn.addText(c);
        // Aquí puedes agregar el formato de impresión que desees
        prn.addText(`Agencia: ${ticketData.agencia}\n`);
        prn.addText(`Transacción: ${ticketData.transaccion}\n`);
        prn.addText(`Fecha: ${ticketData.fecha}\n`);
        // Movimientos
        ticketData.movimientos.forEach((movimiento) => {
            prn.addText(`\nContrato: ${movimiento.contrato}\n`);
            prn.addText(`Cuenta: \n${movimiento.individuo}\n${movimiento.domicilio}\n${movimiento.colonia}\n${movimiento.localidad}\n`);
            prn.addText(`Periodo: ${movimiento.periodo}\n`);
            prn.addText(`Tipo: ${movimiento.tipoPago}\n`);
            prn.addText(`No. Documento: ${movimiento.documento}\n\n`);

            // Conceptos
            movimiento.conceptos.forEach((concepto) => {
                const formattedLine = formatConcept(concepto.conceptoCobro, concepto.importe);
                prn.addText(`${formattedLine}\n`);
            });

            prn.addText('\n-----------------------------------------------\n');
            prn.addText(`${formatConcept("Total Docum: ", movimiento.totalDocumento)}\n`);
            prn.addText(`${formatConcept("Abonos: ", movimiento.abonos)}\n`);
            prn.addText(`${formatConcept("Adeudos: ", movimiento.adeudo)}\n`);
            prn.addText(`${formatConcept("Pago: ", movimiento.pago)}\n`);
            prn.addText('-----------------------------------------------\n');
            
        });

        prn.addText(`${formatConcept("Total recibido: ", ticketData.recibido)}\n`);
        prn.addText(`${formatConcept("Cambio:", ticketData.cambio)}\n`);
        prn.addText('Gracias por su pago!\n\n');
        prn.addFeedLine(5);
        prn.addCut(prn.CUT_FEED);

        prn.send();
        setTransacctionId();
    };

    return (
        <div id="thermalPrinter">
            <span className="status-label">Impresora: {connectionStatus}</span> {" "}
            (<a href="#" onClick={() => requestPrinterInfo()}>
                Cambiar impresora
            </a>)
        </div>
    );
};

export default ThermalPrinter;