import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import { useNavigate } from "react-router-dom";


import {Users} from '../../services/api';

import logo from '../../img/logo-dark.png'

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {new Date().getFullYear()}
            {''} {'Sistema de Pagos de Agua Potable y Alcantarillado de Jerez. ♥ '}
            <Link color="inherit" href="">
                by Wotbi
            </Link>{' '}

        </Typography>
    );
}

const theme = createTheme();

export default function Login() {
    const [error, setError] =  useState('');

    let navigate = useNavigate();

    useEffect(() => {
        const user = localStorage.getItem('user');
        if(user){
            navigate("/", { replace: true });
        }
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        Users.login(data.get('email'), data.get('password')).then(result => {
            localStorage.setItem('user', JSON.stringify(result));
            if(result.user !== undefined && "id_caja" in result.user &&  result.user.id_caja){
                localStorage.setItem('caja', result.user.id_caja);
            }
            navigate("/", { replace: true });
        }).catch(e => {
            setError("Nombre de usuario o contraseña incorrectos.");
        });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img alt="" className="h-14 w-14" src={logo}/>
                    <Typography component="h1" variant="h5">
                        Iniciar Sesión
                    </Typography>
                    {error !== '' && <Alert severity="error">{error}</Alert>}
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Correo Eléctronico"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Contraseña"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary"/>}
                            label="Recuerdame"
                        />
                        <Button
                            type="submit"
                            style={{
                                backgroundColor: "#5a162f ",
                                fontSize: "12px",
                                margin: "4px",
                                alignItems: "center"
                            }}
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >
                            Iniciar sesión
                        </Button>
                    </Box>
                </Box>
                <Copyright sx={{mt: 8, mb: 4}}/>
            </Container>
        </ThemeProvider>
    );
}