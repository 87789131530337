import React from "react";
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Title from '../Title/index';
import Paper from '@mui/material/Paper';

export default function Total({total, addSale, resetSale}) {
    return (
        <React.Fragment>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Title> Total</Title>
                <Typography sx={{pb: 4, pt: 4}} id="lblTotal" variant="h3"
                            className="display-4 text-center fw-bold text-primary">
                    ${total}
                </Typography>

                <div className="text-center">
                    <Button onClick={addSale} variant="contained" size="large">
                        FINALIZAR VENTA
                    </Button><br />
                    <Button sx={{mt: 4}} variant="contained" color="success"
                                    onClick={resetSale}>Limpiar venta</Button>
                </div>
            </Paper>
        </React.Fragment>
    )

}
