import React, {useEffect, useState} from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Cashbox, initApi} from '../../services/api';

const theme = createTheme({
    palette: {
        primary: {
            main: '#56122c',
        },
    },
});
export default function OpenCloseCashBoxe({ onSelect, openCashBox, open, onCancel }) {
    const [total, setTotal] = useState('');
    const [totalHistory, setotalHistory] = useState('');

    const focusUsernameInputField = input => {
        if (input) {
          setTimeout(() => {input.focus()}, 100);
        }
      };

    
    useEffect(() => {
        const caja = localStorage.getItem('caja');
        initApi();
        Cashbox.transaction(caja).then(result => {
            const total = Object.keys(result.results)
                .reduce((partialSum, e) => {
                    if (result.results[e].estado === 1) {
                        return partialSum + parseFloat(result.results[e].importe);
                    } else {
                        return partialSum;
                    }
                }, 0);
            setotalHistory(parseFloat(total).toFixed(2));
        });
    }, []);

    return (
        <Dialog open={open}>
            <DialogTitle>{openCashBox ? "Apertura de caja" : "Cierre de caja"}</DialogTitle>
            
            <DialogContent dividers>
            {!openCashBox && <div>Importe total: {totalHistory}</div>}
                <TextField
                    inputProps={{style: {fontSize: 35}}}
                    InputLabelProps={{style: {fontSize: 30, marginTop:5}}}
                    inputRef={focusUsernameInputField}
                    value={total}
                    onChange={(e) => setTotal(e.target.value)}
                    id="standard-basic"
                    label="Total"
                    variant="standard" />
            </DialogContent>
            <DialogActions>
                <ThemeProvider theme={theme}>
                    <Button variant="contained" color="primary"
                        onClick={() => {
                            const t = parseFloat(total);
                            if(t >= 0){
                                onSelect(t);
                                setTotal('');
                            }else{
                                alert("El total debe ser mayor o igual a 0.");
                            }
                            
                        }}>{openCashBox ? "Abrir caja" : "Cerrar caja"}</Button>
                        <Button onClick={onCancel} variant="contained" >Cancelar</Button>
                </ThemeProvider>

            </DialogActions>
        </Dialog>
    );
}