import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

function Content(props) {
    return (
        <Grid item xs={12}>
            <Paper sx={{p: 4, display: 'flex', flexDirection: 'column'}}>
                {props.children}
            </Paper>
        </Grid>

    );
}

Content.propTypes = {
    children: PropTypes.node,
};

export default Content;