import React, { useState, useRef, useEffect } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#56122c',
        },
    },
});
export default function TotalPaid({ addSale, open, importe, onCancel}) {
    const [total, setTotal] = useState('');
    const inputEl = useRef(null);

    useEffect(() => {
            setTimeout(() => { if (inputEl.current) {inputEl.current.focus()} }, 2000);
    });
    
    const totalChange = (e) => {
        if (e.key === 'Enter') {
            if (inputEl.current && parseFloat(inputEl.current.value) >= parseFloat(importe)) {
                addSale(total);
                setTotal('');
            } else {
                alert("La candidad recibida no puede ser menor que el importe total.");
                inputEl.current.focus();
            }
        }
    }

    return (
        <Dialog open={open}>
            <DialogTitle>Total recibido</DialogTitle>
            <DialogContent dividers>
                <TextField
                    inputRef={inputEl}
                    value={total}
                    onChange={(e) => setTotal(e.target.value)}
                    onKeyDown={totalChange}
                    id="standard-basic"
                    label="Total"
                    variant="standard"
                    autoFocus />
            </DialogContent>
            <DialogActions>
                <ThemeProvider theme={theme}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (inputEl.current && parseFloat(inputEl.current.value) >= parseFloat(importe)) {
                                addSale(total);
                                setTotal('');
                            } else {
                                alert("La candidad recibida no puede ser menor que el importe total.");
                                inputEl.current.focus();
                            }
                        }}
                    >Finalizar</Button>
                    <Button onClick={onCancel} variant="contained" >Cancelar</Button>
                </ThemeProvider>

            </DialogActions>
        </Dialog>
    );
}